import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Switch,
  Checkbox,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "../../api";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AddState from "./States/AddState";
import AddCity from "./Cities/AddCity";
import AddRegion from "./Regions/AddRegion";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Locations from "examples/ReuseFunctions/Locations";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const Profile = () => {
  const [statesData, setStatesData] = useState(null);
  const [citiesData, setCitiesData] = useState(null);
  const [areasData, setAreasData] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const {
    states,
    cities,
    setSelectedState,
    setSelectedCity,
    handleStates,
    handleCities,
  } = Locations();

  const fetchStates = async () => {
    try {
      const response = await axios.get("/states");
      const { data } = response;
      setStatesData(data);
    } catch (error) {
      console.error("Fetching states failed:", error);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await axios.get(`/cities`);
      const { data } = response;
      setCitiesData(data);
    } catch (error) {
      console.error("Fetching cities failed:", error);
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`/regions`);
      const { data } = response;
      setAreasData(data);
    } catch (error) {
      console.error("Fetching areas failed:", error);
    }
  };

  useEffect(() => {
    fetchStates();
    fetchCities();
    fetchAreas();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [isRegionOpen, setIsRegionOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleStateClose = () => {
    setIsStateOpen(false);
  };
  const handleStateOpen = () => {
    setIsStateOpen(true);
  };
  const handleCityClose = () => {
    setIsCityOpen(false);
  };
  const handleCityOpen = () => {
    handleStates();
    setIsCityOpen(true);
  };
  const handleRegionClose = () => {
    setIsRegionOpen(false);
  };
  const handleRegionOpen = () => {
    handleStates();
    handleCities();
    setIsRegionOpen(true);
  };
  const handleStateStatus = async (data, isActive) => {
    try {
      const stateResponse = await axios.put(`/states/${data?.state_id}`, {
        is_active: isActive,
      });
      fetchStates();
      fetchCities();
      handleStates();
      fetchSuccess(stateResponse.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const handleCityStatus = async (data, isActive) => {
    try {
      const cityResponse = await axios.put(`/cities/${data?.city_id}`, {
        is_active: isActive,
        state_id: data?.state_id,
      });
      fetchCities();
      fetchAreas();
      handleCities();
      fetchSuccess(cityResponse.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const handleRegionStatus = async (data, isActive) => {
    try {
      const regionResponse = await axios.put(`/regions/${data?.region_id}`, {
        is_active: isActive,
        city_id: data?.city_id,
      });
      fetchAreas();
      fetchSuccess(regionResponse.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const handleBulkStateUpdate = async (activeData) => {
    try {
      const promises = selectedRows.map(async (row) => {
        const state = statesData[row.dataIndex];
        return axios.put(`/states/${state?.state_id}`, {
          is_active: activeData,
        });
      });
      await Promise.all(promises);
      setSelectedRows([]);
      fetchStates();
      handleStates();
      fetchSuccess(activeData ? "States activated" : "States deactivated");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const handleCheckAllboxes = (checked) => {
    // If 'checked' is true, select all rows, otherwise, deselect all rows
    setSelectedRows(
      checked ? statesData.map((_, index) => ({ dataIndex: index })) : []
    );
  };

  const handleCheckboxChange = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (row) => row.dataIndex === rowIndex
      );
      return isSelected
        ? prevSelectedRows.filter((row) => row.dataIndex !== rowIndex)
        : [...prevSelectedRows, { dataIndex: rowIndex }];
    });
  };

  if (!statesData && !citiesData && !areasData) {
    return <PageLoader />;
  }
  const stateColumns = [
    {
      name: "checkbox",
      label: "",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              padding: "16px",
              textAlign: "left",
              backgroundColor: "#f8f9fa",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Checkbox
              checked={
                statesData.length > 0 &&
                selectedRows.length === statesData.length
              }
              onChange={(event) => handleCheckAllboxes(event.target.checked)}
            />
          </th>
        ),
        customBodyRender: (value, tableMeta) => (
          <Checkbox
            checked={selectedRows.some(
              (row) => row.dataIndex === tableMeta.rowIndex
            )}
            onChange={() => handleCheckboxChange(tableMeta.rowIndex)}
          />
        ),
      },
    },
    { name: "state_name", label: "State Name" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) =>
                handleStateStatus(data, event.target.checked)
              }
              color="primary"
            />
          );
        },
      },
    },
  ];
  const cityColumns = [
    { name: "state_name", label: "State Name" },
    { name: "city_name", label: "City Name" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span
            className="active-btn"
            style={{
              borderColor: value ? "#00bdaa" : "#fe346e",
            }}
          >
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      align: "center",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const cityData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                className="make-active-btn"
                sx={{
                  position: "relative",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  const statusData = cityData?.is_active ? 0 : 1;
                  handleCityStatus(cityData, statusData);
                }}
              >
                {cityData?.is_active ? "Make InActive" : "Make Active"}
              </Button>
            </Box>
          );
        },
      },
    },
  ];
  const regionColumns = [
    { name: "state_name", label: "State Name" },
    { name: "city_name", label: "City Name" },
    { name: "region_name", label: "Region Name" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <span
            className="active-btn"
            style={{
              borderColor: value ? "#00bdaa" : "#fe346e",
            }}
          >
            {value ? "Active" : "Inactive"}
          </span>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      align: "center",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const regionData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                className="make-active-btn"
                sx={{
                  position: "relative",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  const statusData = regionData?.is_active ? 0 : 1;
                  handleRegionStatus(regionData, statusData);
                }}
              >
                {regionData?.is_active ? "Make InActive" : "Make Active"}
              </Button>
            </Box>
          );
        },
      },
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          maxWidth: "100vw",
          width: "100%",
          padding: "30px",
          maxHeight: "70%",
        }}
      >
        <SoftBox mt={5} md={12} lg={12}>
          <Grid justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                marginBottom={2}
              >
                <Grid item>
                  <Typography variant="h5" component="div" gutterBottom>
                    Locations
                  </Typography>
                </Grid>

                <Grid item>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {tabIndex !== 0 && (
                      <Button
                        className="success-btn"
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          tabIndex === 0
                            ? handleStateOpen()
                            : tabIndex === 1
                            ? handleCityOpen()
                            : handleRegionOpen();
                        }}
                        color="success"
                        sx={{ marginRight: "10px" }}
                      >
                        {tabIndex === 1 ? "Add City" : "Add Region"}
                      </Button>
                    )}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {tabIndex === 0 && (
                        <>
                          <Button
                            variant="contained"
                            className="success-btn"
                            onClick={() => {
                              handleBulkStateUpdate(true);
                            }}
                            disabled={selectedRows.length === 0}
                            sx={{ marginRight: "10px" }}
                          >
                            Activate
                          </Button>
                          <Button
                            variant="contained"
                            className="success-btn"
                            onClick={() => {
                              handleBulkStateUpdate(false);
                            }}
                            disabled={selectedRows.length === 0}
                          >
                            InActivate
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Card>
                <CardContent className="profile-tabs">
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="States" />
                    <Tab label="Cities" />
                    <Tab label="Regions" />
                  </Tabs>
                  <Box mt={3}>
                    {tabIndex === 0 && (
                      <SoftBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <ThemeProvider theme={getMuiTheme}>
                          <MUIDataTable
                            className="m-states"
                            title={"Manage States"}
                            data={statesData}
                            columns={stateColumns}
                            options={options}
                          />
                        </ThemeProvider>
                      </SoftBox>
                    )}
                    {tabIndex === 1 && (
                      <SoftBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <ThemeProvider theme={getMuiTheme}>
                          <MUIDataTable
                            className="m-cities"
                            title={"Manage Cities"}
                            data={citiesData}
                            columns={cityColumns}
                            options={options}
                          />
                        </ThemeProvider>
                      </SoftBox>
                    )}
                    {tabIndex === 2 && (
                      <SoftBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <ThemeProvider theme={getMuiTheme}>
                          <MUIDataTable
                            className="m-regions"
                            title={"Manage Regions"}
                            data={areasData}
                            columns={regionColumns}
                            options={options}
                          />
                        </ThemeProvider>
                      </SoftBox>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      {isStateOpen && (
        <AddState
          isOpen={isStateOpen}
          onClose={handleStateClose}
          setSelectedState={setSelectedState}
          fetchState={fetchStates}
          handleStates={handleStates}
        />
      )}
      {isCityOpen && (
        <AddCity
          isOpen={isCityOpen}
          onClose={handleCityClose}
          states={states}
          setSelectedState={setSelectedState}
          fetchCities={fetchCities}
          handleStates={handleCities}
        />
      )}
      {isRegionOpen && (
        <AddRegion
          isOpen={isRegionOpen}
          onClose={handleRegionClose}
          states={states}
          cities={cities}
          setSelectedState={setSelectedState}
          setSelectedCity={setSelectedCity}
          fetchAreas={fetchAreas}
        />
      )}
    </DashboardLayout>
  );
};

export default Profile;
