import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal, TextField, Button, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import StatusHistory from "examples/ReuseFunctions/StatusHistory";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};

const RatingComponent = ({ isOpen, onClose, ratingData, fetchData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const [value, setValue] = useState(ratingData?.rating);
  const [comment, setComment] = useState(ratingData?.feedback);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const statusdata = StatusHistory(
        ratingData?.status_history,
        ratingData?.customer_name,
        "Closed"
      );
      const reqData = {
        rating_id: ratingData?.rating_id || null,
        ticket_id: ratingData?.ticket_id,
        customer_id: ratingData?.customer_id,
        employee_id: ratingData?.employee_id,
        rating: value,
        feedback: comment,
      };
      const customerReview = {
        status_id: 7,
        status_history: statusdata,
      };

      const response = await axios.post("/rating", reqData);
      await axios.put(`/tickets/${ratingData?.ticket_id}`, {
        ticketData: customerReview,
      });
      fetchData();
      onClose();
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  return (
    <Modal
      className="rating-popup"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="rating-modal"
      aria-describedby="rating-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 300,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Typography variant="h5" gutterBottom>
            Feedback
          </Typography>
        </Grid>
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography component="legend">Rate This Service</Typography>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Feedback
              </SoftTypography>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={comment}
                onChange={handleCommentChange}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button
                className="success-btn"
                sx={{
                  position: "relative",
                  minWidth: 100,
                }}
                variant="contained"
                onClick={handleSubmit}
                color="success"
              >
                Submit
              </Button>

              <Button
                className="cancel-btn"
                sx={{
                  position: "relative",
                  minWidth: 100,
                  ml: 2.5,
                }}
                variant="contained"
                color="warning"
                onClick={onClose}
                type="cancel"
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default RatingComponent;
