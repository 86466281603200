import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton, Switch } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useSelector } from "react-redux";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none", // "none" will hide checkboxes on rows
  selectableRowsHeader: false, // Set to false to hide the header checkbox
  elevation: 0,
};

const SupervisorsList = () => {
  const user = useSelector((state) => state.auth.user);
  const { fetchError, fetchSuccess } = useSnackbar();
  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const handleSwap = async (userMail) => {
    const swapData = {
      actualEmail: user.email,
      actualRole: user.Role[0],
      actualRoleId: user.roleId,
      actualName: user.name,
      actualUserId: user.userId,
      userMail: userMail,
    };
    try {
      await axios.post("/auth/switch-user", swapData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatus = async (data, isActive) => {
    try {
      await axios.put(`/employee/is-active/${data?.employee_id}`, {
        is_active: isActive,
      });
      fetchData();
      fetchSuccess("Status updated successfully");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const supervisorData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <span
              onClick={() => handleSwap(supervisorData.email)}
              style={{
                cursor: "pointer",
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "address", label: "Address" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const supervisorData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/edit-supervisor/${supervisorData.employee_id}`)
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="edit"
                onClick={() => handleSwap(supervisorData.email)}
              >
                <SwapHorizIcon />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get(`/employee/roles/${3}`);
        const { data } = response;
        setRows(data?.list);
      } catch (error) {
        setError(error);
      }
    },
    []
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => navigate(`/add-supervisor`)}
          >
            Add Supervisor
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-supervisors"
              title={"Manage Supervisors"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default SupervisorsList;
