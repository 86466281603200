import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import axios from "../../api";
import FormatConversationHistory from "./FormatConversationHistory";
import ConversationHistory from "./ConversationHistory";
import DateFormat from "./DateFormat";
import { useParams } from "react-router-dom";
import PageLoader from "./PageLoader";
import io from "socket.io-client";

const AddConversation = ({ data, user }) => {
  const { id } = useParams();
  const currentTime = DateFormat();
  const { fetchError } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [conversationData, setConversationData] = useState(
    JSON.parse(data?.customer_comments) || []
  );
  const audioRef = useRef(new Audio("/sounds/notification.mp3"));
  const socket = useRef(null);

  // Define the socket.io server URL
  const getSocketUrl = () => {
    const protocol = window.location.protocol === "https:" ? "https" : "http";
    const host =
      window.location.host === "localhost:3000"
        ? "localhost:5000"
        : window.location.host;
    return `${protocol}://${host}:5000`;
  };

  // Setup socket.io connection
  const setupSocketIO = useCallback(() => {
    const socketUrl = getSocketUrl();
    socket.current = io(socketUrl, {
      transports: ["websocket"], // Ensure WebSocket transport is used
    });

    socket.current.on("connect", () => {
      console.log("Socket.io connected");
    });

    socket.current.on("message", (message) => {
      try {
        setConversationData((prevData) => [...prevData, message]);

        // Play notification sound
        if (audioRef.current) {
          audioRef.current.play().catch((error) => {
            console.error("Audio play error:", error);
          });
        }
      } catch (error) {
        console.error("Error processing message", error);
      }
    });

    return () => {
      socket.current.disconnect(); // Disconnect the socket on cleanup
    };
  }, []);

  useEffect(() => {
    const cleanup = setupSocketIO();
    return cleanup;
  }, [setupSocketIO]);

  // Fetch ticket data from the API
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const ticketResponse = await axios.get(`/tickets/${id}`);
      const { data } = ticketResponse;
      setTicket(data?.list);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching data", err);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleConversation = async (values, { resetForm }) => {
    const conversationId = `conv-${Date.now()}`;
    const message = {
      id: conversationId,
      text: values.customer_comments,
      sender_id: user?.userId,
      sender_role: user?.Role[0],
      sender_name: user?.name,
      date: currentTime,
    };

    // Optimistically update UI
    setConversationData((prevData) => [...prevData, message]);

    try {
      const ticketData = {
        customer_comments: ConversationHistory(
          JSON.stringify(conversationData),
          values.customer_comments,
          conversationId,
          user?.userId,
          user?.Role[0],
          user?.name
        ),
      };

      // Save the message to the database
      await axios.put(`/tickets/${ticket?.ticket_id}`, { ticketData });
      // Broadcast the message via socket.io
      socket.current.emit("message", message);

      resetForm();
    } catch (err) {
      fetchError(err.response?.data?.error || "An error occurred");

      // Revert optimistic UI update on error
      setConversationData((prevData) =>
        prevData.filter((msg) => msg.id !== conversationId)
      );
    }
  };

  return loading ? (
    <PageLoader />
  ) : (
    <>
      <Typography variant="h5">
        {FormatConversationHistory(JSON.stringify(conversationData), user)}
      </Typography>
      {[2, 3, 5].includes(data?.status_id) && (
        <Formik
          initialValues={{ customer_comments: "" }}
          onSubmit={(values, { resetForm }) => {
            handleConversation(values, { resetForm });
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form
              className="add-comment"
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
              onSubmit={handleSubmit}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  className="multiline-text"
                  variant="outlined"
                  fullWidth
                  name="customer_comments"
                  value={values.customer_comments}
                  onChange={handleChange}
                />
                <Button
                  sx={{ height: "45px" }}
                  className="success-btn"
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={!values.customer_comments}
                >
                  Add
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddConversation;
