// src/layouts/NotFound.js

import React from "react";

const NotFound = ({ user }) => {
  return (
    <div className={`not-found ${!user ? "before-login" : ""}`}>
      <img src="/not-found.png" alt="not-found" />
      {/* <h1>404 - Page Not Found</h1> */}
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
