// AddressComponent.jsx
import React, { useCallback, useEffect, useState } from "react";
import EditAddress from "./EditAddress";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import Locations from "examples/ReuseFunctions/Locations";
import axios from "../../api";
import PageLoader from "examples/ReuseFunctions/PageLoader";

const customScrollbarStyle = {
  // Define the styles for the scrollbar
  "&::-webkit-scrollbar": {
    width: "8px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },

  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};
const AddressComponent = ({
  data,
  fetchSuccess,
  fetchError,
  fetchAddressData,
  handlePrimaryAddress,
}) => {
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const [statusData, setstatusData] = useState(null);

  const fetchTicketData = useCallback(async () => {
    try {
      const response = await axios.get(`/tickets/address/${data?.address_id}`);
      const status_ids = response?.data
        .map((ticket) => [ticket.status_id])
        .flat();
      setstatusData(status_ids);
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [data]);
  useEffect(() => {
    fetchTicketData();
  }, [data, fetchTicketData]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleEditOpen = () => {
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };
  useEffect(() => {
    if (data) {
      setSelectedState(data.state_id);
      setSelectedCity(data.city_id);
    }
  }, [data, setSelectedState, setSelectedCity]);
  if (!statusData) {
    return <PageLoader />;
  }
  return (
    <Paper
      style={{
        padding: "20px",
        marginBottom: "20px",
        border: "1px solid #ccc",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="primary"
                checked={data?.is_primary || false}
                onChange={(event) =>
                  handlePrimaryAddress(
                    event,
                    data?.address_id,
                    data?.customer_id
                  )
                }
              />
            }
            label="Primary"
          />
        </FormGroup>
        {/* Should not display edit button if this address is added to any ticket
         and if the ticket status is (To do, In-Progress, On-Hold, Pending and Re-Open) */}
        {!statusData.some((status) => [2, 3, 4, 5, 8].includes(status)) && (
          <IconButton aria-label="edit" onClick={handleEditOpen}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>State</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="center">
                <span>:</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>{data.state_name}</span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>City</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="center">
                <span>:</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>{data.city_name}</span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Region</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="center">
                <span>:</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>{data.region_name}</span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Address Type</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="center">
                <span>:</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>{data.address_type}</span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="left">
                <span>Address</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6">
              <Box display="flex" justifyContent="center">
                <span>:</span>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "normal" }}>
              <Box display="flex" justifyContent="flex-start">
                <span>{data.address}</span>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Modal open={isEditOpen} onClose={handleEditClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            maxWidth: 500,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
            ...customScrollbarStyle,
          }}
        >
          <EditAddress
            data={data}
            states={states}
            cities={cities}
            areas={areas}
            setSelectedState={setSelectedState}
            setSelectedCity={setSelectedCity}
            fetchSuccess={fetchSuccess}
            fetchError={fetchError}
            fetchAddressData={fetchAddressData}
            onClose={handleEditClose}
            setIsEditOpen={setIsEditOpen}
          />
        </Box>
      </Modal>
    </Paper>
  );
};

export default AddressComponent;
