import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const Dashboard = () => {
  const [counts, setCounts] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [rating, setRating] = useState(null); // State for rating data
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const statusEmojis = {
    Open: "📂",
    ToDo: "📋",
    "In-Progress": "🔄",
    "On-Hold": "⏸️",
    Pending: "⏳",
    Done: "🛠️",
    Closed: "✅",
    "Re-Open": "🔁",
    Cancelled: "❌",
  };

  useEffect(() => {
    const storedInterval = localStorage.getItem("refreshInterval");
    if (storedInterval) {
      setRefreshInterval(Number(storedInterval));
    }

    const fetchTicketCounts = async () => {
      let response;
      try {
        if (user.Role.includes("employee")) {
          response = await axios.get(
            `/tickets/employee/ticket-counts/${user?.userId}`
          );
        } else {
          response = await axios.get("/tickets/ticket-counts");
        }
        setCounts(response.data.list);
        setTotalCount(response?.data?.total_count);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmployeeRating = async () => {
      try {
        if (user.Role.includes("employee")) {
          const response = await axios.get(`/rating/employee/${user.userId}`);
          setRating(response.data.list);
        }
      } catch (error) {
        console.error("Failed to fetch rating", error);
      }
    };

    fetchTicketCounts();
    fetchEmployeeRating();

    if (refreshInterval > 0) {
      const interval = setInterval(fetchTicketCounts, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [user, refreshInterval]);

  const handleClick = (status_id) => {
    if (user.Role.includes("employee")) {
      navigate(`/employee-tickets-list?status_id=${status_id}`);
    } else {
      navigate(`/admin/tickets-list?status_id=${status_id}`);
    }
  };

  const handleRefreshIntervalChange = (newInterval) => {
    setRefreshInterval(newInterval);
    localStorage.setItem("refreshInterval", newInterval);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Refresh Interval Autocomplete */}
      {user.Role.includes("admin") && (
        <FormControl sx={{ m: 2, minWidth: 150 }}>
          <Typography sx={{ fontSize: 14 }}>Refresh Interval</Typography>
          <Autocomplete
            options={[
              { label: "Off", value: 0 },
              { label: "2 minutes", value: 120000 },
              { label: "5 minutes", value: 300000 },
              { label: "10 minutes", value: 600000 },
            ]}
            getOptionLabel={(option) => option.label}
            value={
              [
                { label: "Off", value: 0 },
                { label: "2 minutes", value: 120000 },
                { label: "5 minutes", value: 300000 },
                { label: "10 minutes", value: 600000 },
              ].find((option) => option.value === refreshInterval) || null
            }
            onChange={(event, newValue) =>
              handleRefreshIntervalChange(newValue?.value || 0)
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </FormControl>
      )}

      <Grid container spacing={3} className="static-cards">
        {/* Total Tickets Card */}
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <div
            onClick={() =>
              navigate(
                user.Role.includes("employee")
                  ? "/employee-tickets-list"
                  : "/admin/tickets-list"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <MiniStatisticsCard
              title={{ text: "Total Tickets" }}
              count={totalCount}
              percentage={{ color: "primary", text: "Tickets" }}
              icon={{ color: "primary", component: "🎟️" }}
            />
          </div>
        </Grid>
        {user.Role.includes("employee") && rating > 0 && (
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <MiniStatisticsCard
              title={{ text: "Rating" }}
              count={rating}
              percentage={{
                color: "primary",
                text: `${"★".repeat(Math.floor(rating))}${
                  rating % 1 >= 0.5 ? "½" : ""
                } / 5`,
              }}
              icon={{ color: "primary", component: <StarIcon /> }}
            />
          </Grid>
        )}

        {/* Status Cards */}
        {Object.entries(counts).map(([status, { status_id, total_count }]) => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={status_id}>
            <div
              onClick={() => handleClick(status_id)}
              style={{ cursor: "pointer" }}
            >
              <MiniStatisticsCard
                title={{ color: "black", text: status }}
                count={total_count}
                percentage={{ color: "primary", text: "Tickets" }}
                icon={{
                  color: "primary",
                  component: statusEmojis[status] || "🎟️",
                }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
