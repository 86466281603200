import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { setAuthToken } from "../../../api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useDispatch } from "react-redux";
import { userLogin } from "store/reducers/auth";
import {
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../components/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

function AdminSignIn() {
  const { fetchError } = useSnackbar();
  const [rememberMe, setRememberMe] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .typeError("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  });

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(`/auth/admin/login`, {
        email: values.email,
        password: values.password,
        rememberMe,
      });
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        const user = await axios.get("/auth");
        dispatch(userLogin({ user: user.data }));
        navigate("/dashboard");
      }
    } catch (error) {
      fetchError(error.response.data.error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <DefaultNavbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Card
          className="signin-form signin-admin-form"
          sx={{
            maxWidth: "30vw",
            width: "100%",
            padding: "30px",
          }}
        >
          <Typography sx={{ textAlign: "center" }}>Sign In</Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({
              isSubmitting,
              touched,
              errors,
              handleChange,
              handleBlur,
              values,
            }) => (
              <Form>
                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                  </SoftBox>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    placeholder="Email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Password
                    </SoftTypography>
                  </SoftBox>
                  <TextField
                    className="view-password"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </SoftBox>

                {/* Remember Me */}
                <SoftBox display="flex" alignItems="center">
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleSetRememberMe}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;Remember me
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={2}>
                  <Typography
                    variant="body2"
                    fontWeight="medium"
                    onClick={handleForgotPassword}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    component={Link}
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Typography>
                </SoftBox>
                {/* Sign In Button */}
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    className="success-btn"
                    variant="gradient"
                    color="success"
                    fullWidth
                    type="submit"
                    disabled={Object.values(errors).length > 0 ? true : false}
                  >
                    Sign In
                  </SoftButton>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
      <Footer />
    </>
  );
}

export default AdminSignIn;
