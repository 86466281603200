import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Button,
  Card,
  Typography,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Modal,
  InputAdornment,
  IconButton,
  Autocomplete,
  FormControl,
  Avatar,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "../../api";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import Locations from "examples/ReuseFunctions/Locations";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SoftTypography from "components/SoftTypography";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import { Edit as EditIcon } from "@mui/icons-material";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
});
const addressValidationSchema = Yup.object({
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Region is required"),
  address: Yup.string().required("Address is required"),
});
const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Current password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  newPassword: Yup.string()
    .required("New password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const AdminProfile = () => {
  const [userData, setUserData] = useState(null);
  const { fetchError, fetchSuccess } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [formikProps, setFormikProps] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [initialRegion, setInitialRegion] = useState(null);
  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/employee/${user?.userId}`);
      const { data } = response;
      setSelectedState(data?.state_id);
      setSelectedCity(data?.city_id);
      setInitialState({
        state_id: data?.state_id,
        state_name: data?.state_name,
      });
      setInitialCity({
        city_id: data?.city_id,
        city_name: data?.city_name,
      });
      setInitialRegion({
        region_id: data?.region_id,
        region_name: data?.region_name,
      });
      setUserData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [user, setSelectedState, setSelectedCity]);

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpenOtpModal = () => {
    setOtpModalOpen(true);
  };

  const handleCloseOtpModal = () => {
    setOtpModalOpen(false);
  };

  const handleSendOtp = async (email) => {
    try {
      await axios.post("/otp/send-otp", { email });
      handleOpenOtpModal();
    } catch (error) {
      fetchError(error.response.data.error);
      // handleCloseOtpModal();
    }
  };

  const handleOtpSubmit = async (values) => {
    try {
      const response = await axios.put(`/employee/${userData.employee_id}`, {
        ...values,
        otp,
      });
      if (response.status === 200) {
        handleCloseOtpModal();
        fetchSuccess("Email updated successfully");
        fetchUserData();
      }
    } catch (error) {
      fetchError("OTP verification failed");
    }
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropzone = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
    },
    onDrop: (acceptedFiles) => {
      // Append new files to the existing uploadedFiles array
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
  }, [dropzone.acceptedFiles]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <Card
          sx={{
            maxWidth: "100vw",
            width: "100%",
            padding: "30px",
            maxHeight: "70%",
          }}
        >
          <SoftBox mt={5} md={12} lg={12}>
            <Grid justifyContent="center">
              <Grid item xs={12} md={8} lg={6}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item>
                    <Typography variant="h5" component="div" gutterBottom>
                      Profile Information
                    </Typography>
                  </Grid>
                </Grid>
                <Box className="profile-tabs">
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Personal Information" />
                    <Tab label="Update Address" />
                    <Tab label="Change Password" />
                  </Tabs>
                  <Box mt={3}>
                    {tabIndex === 0 && (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: userData?.name || "",
                          email: userData?.email || "",
                          phone: userData?.phone || "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                          if (values.email !== userData.email) {
                            setNewEmail(values.email);
                            setFormikProps({ values, actions });
                            handleSendOtp(values.email);
                          } else {
                            // delete values.email;
                            try {
                              if (uploadedFiles && uploadedFiles.length > 0) {
                                const file = uploadedFiles[0];
                                const fileName = `${Date.now()}-${file.name}`;
                                values.profile_image = fileName;
                                const params = {
                                  Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                                  Key: fileName,
                                  Body: file,
                                  ContentType: file.type,
                                };

                                s3.upload(params, async (err, data) => {
                                  if (err) {
                                    console.error("Error uploading file:", err);
                                  } else {
                                    try {
                                      const response = await axios.put(
                                        `/employee/${userData.employee_id}`,
                                        values
                                      );
                                      fetchSuccess(response.data.message);
                                      fetchUserData();
                                    } catch (error) {
                                      fetchError(error.response.data.error);
                                    }
                                  }
                                });
                              } else {
                                const response = await axios.put(
                                  `/employee/${userData.employee_id}`,
                                  values
                                );
                                fetchSuccess(response.data.message);
                                fetchUserData();
                              }
                            } catch (error) {
                              fetchError(error.response.data.error);
                            }
                          }
                        }}
                      >
                        {({ values, errors, touched, handleChange }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  error={!!errors.name && touched.name}
                                  helperText={touched.name && errors.name}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  error={!!errors.email && touched.email}
                                  helperText={touched.email && errors.email}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  placeholder="Phone Number"
                                  inputProps={{ maxLength: 10 }}
                                  name="phone"
                                  value={values.phone}
                                  onChange={handleChange}
                                  error={!!errors.phone && touched.phone}
                                  helperText={touched.phone && errors.phone}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className="profile-section"
                              >
                                {uploadedFiles.length > 0 &&
                                uploadedFiles[0]?.preview ? (
                                  <Avatar
                                    className="profile-img"
                                    sx={{
                                      width: { xs: 84, lg: 84 },
                                      height: { xs: 84, lg: 84 },
                                      cursor: "pointer",
                                    }}
                                    src={uploadedFiles[0]?.preview}
                                  />
                                ) : (
                                  <Avatar
                                    className="profile-img"
                                    sx={{
                                      width: { xs: 84, lg: 84 },
                                      height: { xs: 84, lg: 84 },
                                      cursor: "pointer",
                                    }}
                                    src={
                                      userData?.profile_image
                                        ? `${process.env.REACT_APP_CLOUD_FRONT_URL}/${userData?.profile_image}`
                                        : "/assets/images/team-3.jpg"
                                    }
                                  />
                                )}
                                <Box
                                  className="profile-edit"
                                  sx={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  {...dropzone.getRootProps()}
                                >
                                  <Box>
                                    <label htmlFor="image-upload-input">
                                      <input
                                        name="profile_image"
                                        {...dropzone.getInputProps({
                                          name: "profile_image",
                                        })}
                                      />
                                      <IconButton
                                        aria-label="edit"
                                        className="edit-icon"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </label>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  className="success-btn"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                >
                                  Save Changes
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    )}
                    {tabIndex === 1 && (
                      <Formik
                        initialValues={{
                          state_id: userData?.state_id || "",
                          city_id: userData?.city_id || "",
                          region_id: userData?.region_id || "",
                          address: userData?.address || "",
                        }}
                        validationSchema={addressValidationSchema}
                        onSubmit={async (values) => {
                          values.email = userData.email;
                          const { state_id, city_id, ...rest } = values;
                          try {
                            await axios.put(
                              `/employee/${userData.employee_id}`,
                              { ...rest }
                            );
                            fetchSuccess("Address updated successfully");
                            fetchUserData();
                          } catch (error) {
                            fetchError(error.response.data.error);
                          }
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          errors,
                          touched,
                          setFieldValue,
                        }) => (
                          <Form className="update-address-popup">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    State
                                  </SoftTypography>
                                  <Autocomplete
                                    id="state-autocomplete"
                                    options={states || []}
                                    getOptionLabel={(option) =>
                                      option.state_name || ""
                                    }
                                    value={
                                      values.state_id ? initialState : null
                                    }
                                    onChange={(event, newValue) => {
                                      setFieldValue("city_id", "");
                                      setFieldValue("region_id", "");

                                      setFieldValue(
                                        "state_id",
                                        newValue ? newValue.state_id : ""
                                      );
                                      setInitialState({
                                        state_id: newValue?.state_id || null,
                                        state_name:
                                          newValue?.state_name || null,
                                      });
                                      setSelectedState(
                                        newValue ? newValue.state_id : ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="state_id"
                                        variant="outlined"
                                        error={
                                          !!errors.state_id && touched.state_id
                                        }
                                        helperText={
                                          touched.state_id && errors.state_id
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    City
                                  </SoftTypography>
                                  <Autocomplete
                                    id="city-autocomplete"
                                    options={cities || []}
                                    getOptionLabel={(option) =>
                                      option.city_name || ""
                                    }
                                    value={values.city_id ? initialCity : null}
                                    onChange={(event, newValue) => {
                                      setFieldValue("region_id", "");
                                      setFieldValue(
                                        "city_id",
                                        newValue ? newValue.city_id : ""
                                      );
                                      setInitialCity({
                                        city_id: newValue?.city_id || null,
                                        city_name: newValue?.city_name || null,
                                      });
                                      setSelectedCity(
                                        newValue ? newValue.city_id : ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="city_id"
                                        variant="outlined"
                                        error={
                                          !!errors.city_id && touched.city_id
                                        }
                                        helperText={
                                          touched.city_id && errors.city_id
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Area
                                  </SoftTypography>
                                  <Autocomplete
                                    id="region-autocomplete"
                                    options={areas || []}
                                    getOptionLabel={(option) =>
                                      option.region_name || ""
                                    }
                                    value={
                                      values.region_id ? initialRegion : null
                                    }
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "region_id",
                                        newValue ? newValue.region_id : ""
                                      );
                                      setInitialRegion({
                                        region_id: newValue?.region_id || null,
                                        region_name:
                                          newValue?.region_name || null,
                                      });
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="region_id"
                                        variant="outlined"
                                        error={
                                          errors.region_id && touched.region_id
                                        }
                                        helperText={
                                          touched.region_id && errors.region_id
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Address
                                </SoftTypography>
                                <TextField
                                  fullWidth
                                  placeholder="Address"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                  error={errors.address && touched.address}
                                  helperText={touched.address && errors.address}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    className="success-btn"
                                    sx={{
                                      position: "relative",
                                      minWidth: 100,
                                    }}
                                    variant="contained"
                                    type="submit"
                                    color="success"
                                  >
                                    Update
                                  </Button>

                                  <Button
                                    className="cancel-btn"
                                    sx={{
                                      position: "relative",
                                      minWidth: 100,
                                      ml: 2.5,
                                    }}
                                    variant="contained"
                                    color="warning"
                                    onClick={() => {
                                      setTabIndex(0);
                                      fetchUserData();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    )}
                    {tabIndex === 2 && (
                      <Formik
                        initialValues={{
                          password: "",
                          newPassword: "",
                        }}
                        validationSchema={passwordValidationSchema}
                        onSubmit={async (values, { resetForm }) => {
                          try {
                            const response = await axios.put(
                              `/employee/password/${user?.userId}`,
                              values
                            );
                            resetForm();
                            fetchSuccess(response.data.message);
                          } catch (error) {
                            fetchError(error.response.data.error);
                          }
                        }}
                      >
                        {({ values, errors, touched, handleChange }) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  className="view-password"
                                  fullWidth
                                  placeholder="Current Password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword(!showPassword)
                                          }
                                        >
                                          {showPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={!!errors.password && touched.password}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  className="view-password"
                                  placeholder="New Password"
                                  type={showNewPassword ? "text" : "password"}
                                  name="newPassword"
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  error={
                                    !!errors.newPassword && touched.newPassword
                                  }
                                  helperText={
                                    touched.newPassword && errors.newPassword
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowNewPassword(!showNewPassword)
                                          }
                                        >
                                          {showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  className="success-btn"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                >
                                  Update Password
                                </Button>
                                <Button
                                  className="cancel-btn"
                                  sx={{
                                    position: "relative",
                                    minWidth: 100,
                                    ml: 2.5,
                                  }}
                                  variant="contained"
                                  color="warning"
                                  onClick={() => setTabIndex(0)}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      )}
      <Modal
        open={otpModalOpen}
        onClose={handleCloseOtpModal}
        aria-labelledby="otp-modal"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            bgcolor: "background.paper",
          }}
        >
          <Typography id="otp-modal" variant="h6" component="h2">
            Enter OTP
          </Typography>
          <TextField
            placeholder="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            sx={{ mt: 2, width: "100%" }}
          />
          <Button
            className="success-btn"
            onClick={() =>
              handleOtpSubmit({
                ...formikProps.values,
                email: newEmail,
                otp,
              })
            }
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default AdminProfile;
