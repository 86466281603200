import React from "react";
import axios from "../../api";
import {
  Card,
  TextField,
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Locations from "examples/ReuseFunctions/Locations";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Enter a valid phone number")
    .required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  region_id: Yup.string().required("Area is required"),
});

const initialValues = {
  name: "",
  email: "",
  phone: "",
  password: "",
  address: "",
  state_id: "",
  city_id: "",
  region_id: "",
  confirm_password: "",
};

const AddSupervisor = () => {
  const navigate = useNavigate();
  const { states, cities, areas, setSelectedState, setSelectedCity } =
    Locations();
  const { fetchError, fetchSuccess } = useSnackbar();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
          Add New Supervisor
        </SoftTypography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const { state_id, city_id, confirm_password, ...rest } = values;
            try {
              await axios.post("/employee/add-employee", {
                ...rest,
                role_id: 3,
              });
              fetchSuccess("Supervisor created successfully");
              navigate("/supervisors-list");
            } catch (err) {
              fetchError(err.response.data.error);
            }
          }}
        >
          {({ errors, touched, handleChange, setFieldValue, values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Name
                    </SoftTypography>
                    <TextField
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.name && touched.name}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone Number
                    </SoftTypography>
                    <TextField
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone && touched.phone}
                      helperText={touched.phone && errors.phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                    <TextField
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.email && touched.email}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Grid>
                &nbsp;
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Password
                    </SoftTypography>
                    <TextField
                      className="view-password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.password && touched.password}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Confirm Password
                    </SoftTypography>
                    <TextField
                      className="view-password"
                      name="confirm_password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={values.confirm_password}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={
                        !!errors.confirm_password && touched.confirm_password
                      }
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </SoftTypography>
                    <Autocomplete
                      id="state-autocomplete"
                      options={states || []}
                      getOptionLabel={(option) => option.state_name || ""}
                      value={
                        states?.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("city_id", "");
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "state_id",
                          newValue ? newValue.state_id : ""
                        );
                        setSelectedState(newValue ? newValue.state_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state_id"
                          variant="outlined"
                          error={!!errors.state_id && touched.state_id}
                          helperText={touched.state_id && errors.state_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </SoftTypography>
                    <Autocomplete
                      id="city-autocomplete"
                      options={cities || []}
                      getOptionLabel={(option) => option.city_name || ""}
                      value={
                        cities?.find(
                          (city) => city.city_id === values.city_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("region_id", "");
                        setFieldValue(
                          "city_id",
                          newValue ? newValue.city_id : ""
                        );
                        setSelectedCity(newValue ? newValue.city_id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city_id"
                          variant="outlined"
                          error={!!errors.city_id && touched.city_id}
                          helperText={touched.city_id && errors.city_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Area
                    </SoftTypography>
                    <Autocomplete
                      id="region-autocomplete"
                      options={areas || []}
                      getOptionLabel={(option) => option.region_name || ""}
                      value={
                        areas?.find(
                          (city) => city.region_id === values.region_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "region_id",
                          newValue ? newValue.region_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="region_id"
                          variant="outlined"
                          error={!!errors.region_id && touched.region_id}
                          helperText={touched.region_id && errors.region_id}
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address
                    </SoftTypography>
                    <TextField
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={!!errors.address && touched.address}
                      helperText={touched.address && errors.address}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <SoftButton
                      className="success-btn"
                      variant="gradient"
                      color="success"
                      type="submit"
                    >
                      Save
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      className="cancel-btn"
                      variant="gradient"
                      color="warning"
                      onClick={() => navigate(`/supervisors-list`)}
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default AddSupervisor;
