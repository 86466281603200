import React, { useEffect, useState } from "react";
import { TextField, Grid, Card, Box, IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftButton from "components/SoftButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";

const validationSchema = Yup.object({
  on_hold_duration_days: Yup.number()
    .typeError("Duration days must be a number")
    .required("Duration days is required")
    .min(1, "Duration days must be at least 1")
    .max(31, "Duration days must be at most 31"),
  expiration_notice_days: Yup.number()
    .typeError("Notice days must be a number")
    .required("Notice days is required")
    .min(1, "Notice days must be at least 1")
    .max(31, "Notice days must be at most 31"),
  days_to_escalation: Yup.number()
    .typeError("Escalation days must be a number")
    .required("Escalation days is required")
    .min(1, "Escalation days must be at least 1")
    .max(31, "Escalation days must be at most 31"),
});
const GeneralPreferences = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const [preferanceData, setPreferanceData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const fetchPreferencesData = async () => {
    try {
      const response = await axios.get("/general-preferences");
      const { data } = response;
      setPreferanceData(data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };
  useEffect(() => {
    fetchPreferencesData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="view-ticket"
        style={{
          padding: "30px",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SoftTypography variant="h5" style={{ marginBottom: "18px" }}>
            General Preference
          </SoftTypography>
          {!isEdit && (
            <IconButton
              sx={{ cursor: "pointer" }}
              onClick={() => setIsEdit(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
        <Formik
          enableReinitialize
          initialValues={{
            on_hold_duration_days: preferanceData?.on_hold_duration_days || "",
            expiration_notice_days:
              preferanceData?.expiration_notice_days || "",
            days_to_escalation: preferanceData?.days_to_escalation || "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const response = await axios.post("general-preferences", values);
              fetchPreferencesData();
              setIsEdit(false);
              fetchSuccess(response.data.message);
            } catch (err) {
              fetchError(err.response.data.error);
            }
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            errors,
            touched,
            values,
            resetForm,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Status On-hold duration days
                    </SoftTypography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={values?.on_hold_duration_days}
                      name="on_hold_duration_days"
                      disabled={!isEdit}
                      onChange={(event) => {
                        setFieldValue(
                          "on_hold_duration_days",
                          event.target.value
                        );
                      }}
                      error={
                        errors.on_hold_duration_days &&
                        touched.on_hold_duration_days
                      }
                      helperText={
                        touched.on_hold_duration_days &&
                        errors.on_hold_duration_days
                      }
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Subscription expiration notice days
                    </SoftTypography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={values?.expiration_notice_days}
                      name="expiration_notice_days"
                      disabled={!isEdit}
                      onChange={(event) => {
                        setFieldValue(
                          "expiration_notice_days",
                          event.target.value
                        );
                      }}
                      error={
                        errors.expiration_notice_days &&
                        touched.expiration_notice_days
                      }
                      helperText={
                        touched.expiration_notice_days &&
                        errors.expiration_notice_days
                      }
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Days to show escalation button
                    </SoftTypography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={values?.days_to_escalation}
                      name="days_to_escalation"
                      disabled={!isEdit}
                      onChange={(event) => {
                        setFieldValue("days_to_escalation", event.target.value);
                      }}
                      error={
                        errors.days_to_escalation && touched.days_to_escalation
                      }
                      helperText={
                        touched.days_to_escalation && errors.days_to_escalation
                      }
                    />
                  </SoftBox>
                </Grid>
              </Grid>
              {isEdit && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SoftButton
                        className="success-btn"
                        variant="gradient"
                        color="success"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </SoftButton>
                    </Grid>
                    <Grid item>
                      <SoftButton
                        className="cancel-btn"
                        variant="gradient"
                        color="warning"
                        onClick={() => {
                          resetForm();
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </SoftButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </DashboardLayout>
  );
};

export default GeneralPreferences;
