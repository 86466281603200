import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const cityValidationSchema = Yup.object({
  name: Yup.string().required("Category is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
});

const AddCategories = ({ isOpen, onClose, fetchData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const [assets, setAssets] = useState([]);

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get("/asset-types", {
        params: {
          is_active: 1,
        },
      });
      const assetData =
        response.data.list && Array.isArray(response.data.list)
          ? response.data.list
          : [];

      setAssets(assetData);
    } catch (error) {
      console.error(error.response?.data?.error);
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Category
        </Typography>
        <Formik
          initialValues={{
            name: "",
            asset_type_id: null,
          }}
          validationSchema={cityValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const response = await axios.post("/category", values);
              fetchData();
              onClose();
              fetchSuccess(response.data.message);
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      placeholder="Category"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name && touched.name}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={assets || []}
                      getOptionLabel={(option) => option.asset_type_name || ""}
                      onChange={(event, value) => {
                        setFieldValue(
                          "asset_type_id",
                          value ? value.asset_type_id : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Asset"
                          error={
                            !!errors.asset_type_id && touched.asset_type_id
                          }
                          helperText={
                            touched.asset_type_id && errors.asset_type_id
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="success-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                      }}
                      variant="contained"
                      type="submit"
                      color="success"
                    >
                      Save
                    </Button>
                    <Button
                      className="cancel-btn"
                      sx={{
                        position: "relative",
                        minWidth: 100,
                        ml: 2.5,
                      }}
                      variant="contained"
                      color="warning"
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
export default AddCategories;
