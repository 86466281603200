import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import axios from "../../api";
import SoftBox from "components/SoftBox";
import AddSubscriptionType from "./AddSubscriptionType";
import PageLoader from "examples/ReuseFunctions/PageLoader";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollFullHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
          responsiveScrollMaxHeight: {
            maxHeight: "unset",
            overflowX: "auto",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const SubscriptionTypesList = () => {
  const [subscriptionTypeData, setSubscriptionTypeData] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [assetTypes, setAssetTypes] = React.useState(null);
  const [assetTypeFilter, setAssetTypeFilter] = React.useState(null);

  const { fetchError, fetchSuccess } = useSnackbar();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStatus = async (data, isActive) => {
    try {
      const response = await axios.put(
        `/subscription-types/${data?.subscription_type_id}`,
        {
          is_active: isActive,
        }
      );
      fetchData();
      fetchSuccess(response.data.message);
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const fetchData = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get("/subscription-types", {
        params: {
          filters: {
            asset_type_id: assetTypeFilter?.asset_type_id,
          },
        },
      });
      setSubscriptionTypeData(response?.data?.list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error);
    }
  }, [assetTypeFilter]);

  React.useEffect(() => {
    fetchData();
  }, [assetTypeFilter, fetchData]);

  const handleBulkStatusUpdate = async (isActive) => {
    try {
      const promises = selectedRows.map(async (row) => {
        const subscriptionType = subscriptionTypeData[row.dataIndex];
        return axios.put(
          `/subscription-types/${subscriptionType?.subscription_type_id}`,
          {
            is_active: isActive,
          }
        );
      });
      await Promise.all(promises);
      setSelectedRows([]);
      fetchData();
      fetchSuccess(
        isActive
          ? "Subscription type activated"
          : "Subscription type deactivated"
      );
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const handleCheckAllboxes = (checked) => {
    // If 'checked' is true, select all rows, otherwise, deselect all rows
    setSelectedRows(
      checked
        ? subscriptionTypeData.map((_, index) => ({ dataIndex: index }))
        : []
    );
  };

  const handleCheckboxChange = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (row) => row.dataIndex === rowIndex
      );
      return isSelected
        ? prevSelectedRows.filter((row) => row.dataIndex !== rowIndex)
        : [...prevSelectedRows, { dataIndex: rowIndex }];
    });
  };
  const fetchAssets = async () => {
    try {
      const assetTypesData = await axios.get("/asset-types");
      setAssetTypes(assetTypesData?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchAssets();
  }, []);

  // if (loading) {
  //   return <PageLoader />;
  // }
  const columns = [
    {
      name: "checkbox",
      label: "",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              padding: "16px",
              textAlign: "left",
              backgroundColor: "#f8f9fa",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Checkbox
              checked={
                subscriptionTypeData.length > 0 &&
                selectedRows.length === subscriptionTypeData.length
              }
              onChange={(event) => handleCheckAllboxes(event.target.checked)}
            />
          </th>
        ),
        customBodyRender: (value, tableMeta) => (
          <Checkbox
            checked={selectedRows.some(
              (row) => row.dataIndex === tableMeta.rowIndex
            )}
            onChange={() => handleCheckboxChange(tableMeta.rowIndex)}
          />
        ),
      },
    },
    { name: "subscription_type_name", label: "Subscription Type" },
    { name: "asset_type_name", label: "Asset Type" },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
  ];

  return (
    <>
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
          className="manage-tickets-header"
        >
          <Grid className="dpflex">
            <FormControl fullWidth sx={{ paddingLeft: "10px" }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                sx={{ paddingLeft: "10px" }}
              >
                Asset Types
              </SoftTypography>
              <Autocomplete
                options={assetTypes || []}
                getOptionLabel={(option) => option.asset_type_name}
                value={assetTypeFilter}
                onChange={(event, value) => {
                  setAssetTypeFilter(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
          <div style={{ display: "block", justifyContent: "flex-end" }}>
            <Button
              className="success-btn"
              // variant="gradient"
              color="success"
              onClick={() => {
                handleOpen();
              }}
              sx={{ marginRight: "10px", padding: 2 }}
            >
              Add Subscription Type
            </Button>
            <Button
              // variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(true);
              }}
              disabled={selectedRows.length === 0}
              sx={{ marginRight: "10px", padding: 2 }}
            >
              Activate
            </Button>
            <Button
              // variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(false);
              }}
              disabled={selectedRows.length === 0}
              sx={{ padding: 2 }}
            >
              InActivate
            </Button>
          </div>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loading ? (
            <PageLoader />
          ) : (
            <ThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                className="manage-tickets m-category"
                title={"Manage Subscription Types"}
                data={subscriptionTypeData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          )}
        </SoftBox>
      </Card>
      {isOpen && (
        <AddSubscriptionType
          isOpen={isOpen}
          onClose={handleClose}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

export default SubscriptionTypesList;
