import { Box, Typography } from "@mui/material";

const FormatStatusHistoryData = (statusData) => {
  const data = JSON.parse(statusData);
  return data?.map((item, index) => (
    <Box key={index} mb={2} className="status-history">
      <Typography variant="h6">
        {item["Changed by"]} &nbsp;{item["Date"]}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Changed to : <span>{item["Status"]}</span>
      </Typography>
    </Box>
  ));
};

export default FormatStatusHistoryData;
