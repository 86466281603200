import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Checkbox,
  Switch,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import EditTemplate from "./EditTemplate";
import AddTemplate from "./AddTemplate";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const TemplatesList = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [templateData, setTemplateData] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [assetTypes, setAssetTypes] = React.useState(null);
  const [assetTypeFilter, setAssetTypeFilter] = React.useState(null);
  const parseContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    return doc.body.textContent || "";
  };

  const handleEditOpen = (data) => {
    setTemplateData(data);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleStatus = async (isActive, data) => {
    try {
      await axios.put(`/email-templates/${data?.template_id}`, {
        is_active: isActive,
      });
      fetchData();
      fetchSuccess("Status updated successfully");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const handleBulkStatusUpdate = async (isActive) => {
    try {
      const promises = selectedRows.map(async (row) => {
        const template = rows[row.dataIndex];
        return axios.put(`/email-templates/${template.template_id}`, {
          is_active: isActive,
        });
      });
      await Promise.all(promises);
      setSelectedRows([]);
      fetchData();
      fetchSuccess(isActive ? "Templates activated" : "Templates deactivated");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };
  const handleCheckAllboxes = (checked) => {
    // If 'checked' is true, select all rows, otherwise, deselect all rows
    setSelectedRows(
      checked ? rows.map((_, index) => ({ dataIndex: index })) : []
    );
  };

  const handleCheckboxChange = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (row) => row.dataIndex === rowIndex
      );
      return isSelected
        ? prevSelectedRows.filter((row) => row.dataIndex !== rowIndex)
        : [...prevSelectedRows, { dataIndex: rowIndex }];
    });
  };

  const columns = [
    {
      name: "checkbox",
      label: "",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            style={{
              backgroundColor: "#f8f9fa",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Checkbox
              checked={rows.length > 0 && selectedRows.length === rows.length}
              onChange={(event) => handleCheckAllboxes(event.target.checked)}
            />
          </th>
        ),
        customBodyRender: (value, tableMeta) => (
          <Checkbox
            checked={selectedRows.some(
              (row) => row.dataIndex === tableMeta.rowIndex
            )}
            onChange={() => handleCheckboxChange(tableMeta.rowIndex)}
          />
        ),
      },
    },
    { name: "template_name", label: "Name" },
    { name: "template_subject", label: "Subject" },
    {
      name: "template_content",
      label: "Content",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const content = parseContent(value);
          const displayValue =
            content?.length > 20 ? `${content.substring(0, 30)}...` : content;
          return (
            <Tooltip title={content}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "asset_type_name", label: "Asset Type" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(event.target.checked, data)}
              color="primary"
            />
          );
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() => handleEditOpen(data)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get("/email-templates", {
        params: {
          filters: {
            asset_type_id: assetTypeFilter?.asset_type_id,
          },
        },
      });

      setRows(response?.data?.list);
    } catch (error) {
      setError(error);
    }
  }, [assetTypeFilter]);

  React.useEffect(() => {
    fetchData();
  }, [assetTypeFilter, fetchData]);

  const fetchAssets = async () => {
    try {
      const assetTypesData = await axios.get("/asset-types");
      setAssetTypes(assetTypesData?.data?.list);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchAssets();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "block", justifyContent: "space-between" }}>
          <SoftButton variant="gradient" color="info" onClick={handleAddOpen}>
            Add Template
          </SoftButton>

          <div
            style={{
              display: "block",
              justifyContent: "flex-end",
              marginLeft: "10px",
              float: "right",
            }}
          >
            <Grid style={{ float: "left" }}>
              <FormControl sx={{ paddingRight: "10px" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  sx={{ paddingLeft: "10px" }}
                >
                  Asset Types
                </SoftTypography>
                <Autocomplete
                  options={assetTypes || []}
                  getOptionLabel={(option) => option.asset_type_name}
                  value={assetTypeFilter}
                  onChange={(event, value) => {
                    setAssetTypeFilter(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Grid>
            <Button
              variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(true);
              }}
              disabled={selectedRows.length === 0}
              sx={{ marginRight: "10px", marginBottom: "10px" }}
            >
              Activate
            </Button>
            <Button
              variant="contained"
              className="success-btn"
              onClick={() => {
                handleBulkStatusUpdate(false);
              }}
              disabled={selectedRows.length === 0}
              sx={{ marginBottom: "10px" }}
            >
              InActivate
            </Button>
          </div>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-lifts"
              title={"Manage Templates"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
      {isEditOpen && (
        <EditTemplate
          isOpen={isEditOpen}
          onClose={handleEditClose}
          templateData={templateData}
          fetchData={fetchData}
        />
      )}
      {isAddOpen && (
        <AddTemplate
          isOpen={isAddOpen}
          onClose={handleAddClose}
          fetchData={fetchData}
        />
      )}
    </DashboardLayout>
  );
};

export default TemplatesList;
