import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton, Switch, Tooltip } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import { Edit as EditIcon } from "@mui/icons-material";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const PagesList = () => {
  const navigate = useNavigate();
  const { fetchError, fetchSuccess } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const parseContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    return doc.body.textContent || "";
  };

  const handleStatus = async (data, isActive) => {
    try {
      await axios.put(`/pages/${data?.page_id}`, {
        is_active: isActive,
      });
      fetchData();
      fetchSuccess("Status updated successfully");
    } catch (error) {
      fetchError(error.response.data.error);
    }
  };

  const columns = [
    { name: "page_name", label: "Name" },
    { name: "page_title", label: "Title" },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "page_description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const content = parseContent(value);
          const displayValue =
            content?.length > 20 ? `${content.substring(0, 30)}...` : content;
          return (
            <Tooltip title={content}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() => navigate(`/edit-page/${data?.page_id}`)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get("/pages");
        setRows(response?.data?.list);
      } catch (error) {
        console.error("Error", error);
      }
    },
    []
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => navigate("/add-page")}
          >
            Add Page
          </SoftButton>
        </div>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            {rows && (
              <MUIDataTable
                className="manage-lifts"
                title={"Manage Pages"}
                data={rows}
                columns={columns}
                options={options}
              />
            )}
          </ThemeProvider>
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default PagesList;
