import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import wecare from "assets/images/elevator.png";
import wecreative from "assets/images/creative.png";
import wedeliver from "assets/images/deliver.png";
import aboutimg from "assets/images/aboutus.jpg";

const About = () => {
  return (
    <PageLayout>
      <DefaultNavbar />
      <Box component="section" className="about-us" py={8}>
        <Container>
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={6}>
              <Box textAlign="center" lineHeight={1}>
                <img src={aboutimg} alt="About Us" style={{ width: "100%" }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box pl={4} pt={4} lineHeight={1}>
                <Typography mb={2} variant="h2" textGradient>
                  About Innovative Lifts
                </Typography>
                <Typography variant="body2" color="text" mb={3}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Typography>
                <Typography variant="body2" color="text" mb={3}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component="section" py={8} className="why-innovative">
        <Container>
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={12}>
              <Box p={2} textAlign="center" lineHeight={1}>
                <Typography mb={2} variant="h2" textGradient>
                  Why Innovative Lifts
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={2} textAlign="center" lineHeight={1}>
                <Typography
                  variant="h1"
                  color="white"
                  textGradient
                ></Typography>
                <img src={wecare} alt="we care" />
                <Typography variant="h5" mt={2} mb={1}>
                  We Care
                </Typography>
                <Typography variant="body2" color="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} display="flex">
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
              />
              <Box p={2} textAlign="center" lineHeight={1}>
                <Typography
                  variant="h1"
                  color="black"
                  textGradient
                ></Typography>
                <img src={wecreative} alt="we creative" />
                <Typography variant="h5" mt={2} mb={1}>
                  We Are Creative
                </Typography>
                <Typography variant="body2" color="text">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={2} textAlign="center" lineHeight={1}>
                <Typography
                  variant="h1"
                  color="black"
                  textGradient
                ></Typography>
                <img src={wedeliver} alt="we deliver" />
                <Typography variant="h5" mt={2} mb={1}>
                  We Deliver
                </Typography>
                <Typography variant="body2" color="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="hidden">
        <Container>
          <Grid container spacing={3} sx={{ mt: 8 }}>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                name="Nick Willever"
                date="1 day ago"
                review="This is an excellent product, the documentation is excellent and helped me get things done more efficiently."
                rating={5}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                color="info"
                name="Shailesh Kushwaha"
                date="1 week ago"
                review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
                rating={5}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                name="Samuel Kamuli"
                date="3 weeks ago"
                review="Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!."
                rating={5}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </PageLayout>
  );
};
export default About;
