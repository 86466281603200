import DateFormat from "examples/ReuseFunctions/DateFormat";

const AssigneeHistory = (previousdata, employeeName) => {
  const currentTime = DateFormat();
  const newEntry = { "Assigned to": employeeName, Date: currentTime };

  // Flatten the previous data
  const flattenData = (data) => {
    return data.reduce((acc, item) => {
      if (Array.isArray(item)) {
        return acc.concat(flattenData(item));
      } else {
        acc.push(item);
        return acc;
      }
    }, []);
  };

  const historyDataArray = previousdata
    ? flattenData(JSON.parse(previousdata))
    : [];
  historyDataArray.push(newEntry);

  return JSON.stringify(historyDataArray);
};

export default AssigneeHistory;
