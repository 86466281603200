import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Card,
  Switch,
  IconButton,
  Button,
  Accordion,
  Tooltip,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import AddSubscriptionModal from "./AddSubscriptionModal";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddAddress from "./AddAddress";
import { useSelector } from "react-redux";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PageLoader from "examples/ReuseFunctions/PageLoader";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none", // "none" will hide checkboxes on rows
  selectableRowsHeader: false, // Set to false to hide the header checkbox
  elevation: 0,
};

const formatDate = (dateString) => {
  const formateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", formateOptions).format(date);
};

const CustomerList = () => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addressOpen, setAddressOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null); // To manage expanded accordion
  const [customerTickets, setCustomerTickets] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleAddressOpen = (data) => {
    setCustomerId(data);
    setAddressOpen(true);
  };

  const handleAddressClose = () => {
    setAddressOpen(false);
  };

  const handleSwap = async (userMail) => {
    const swapData = {
      actualEmail: user.email,
      actualRole: user.Role[0],
      actualRoleId: user.roleId,
      actualName: user.name,
      actualUserId: user.userId,
      userRole: 5,
      userMail: userMail,
    };
    try {
      await axios.post("/auth/switch-user", swapData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccordionToggle = async (customerId) => {
    setLoading(true);
    setExpanded(expanded === customerId ? null : customerId);
    try {
      const response = await axios.get(`/tickets/customer/${customerId}`);
      const { data } = response;
      setCustomerTickets(data?.list);
      setLoading(false);
    } catch (error) {
      fetchError(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const customerData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <span
              onClick={() => handleAccordionToggle(customerData.customer_id)}
              style={{
                cursor: "pointer",
                color: "blue", // Optional: color for clickable text
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
    },
    { name: "phone", label: "Phone" },
    {
      name: "is_priority",
      label: "Premium",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleSwitchChange(event, data)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Switch
              checked={value}
              onChange={(event) => handleStatus(data, event.target.checked)}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const customerData =
            tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/edit-customer/${customerData.customer_id}`)
                }
              >
                <EditIcon />
              </IconButton>
              {user?.roleId === 2 && (
                <IconButton
                  aria-label="edit"
                  onClick={() => handleSwap(customerData.email)}
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate(`/address-view/${customerData.customer_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
              <Button
                className="add-address-btn"
                sx={{
                  position: "relative",
                }}
                size="small"
                variant="contained"
                onClick={() => {
                  handleAddressOpen(customerData?.customer_id);
                }}
              >
                Add Address
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useCallback(async () => {
    try {
      const response = await axios.get("/customer");
      setRows(response?.data?.list);
    } catch (error) {
      fetchError("Unable to get customers");
    }
  }, [fetchError]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSwitchChange = async (event, rowData) => {
    const newValue = event.target.checked;
    try {
      await axios.put(`/customer/is-priority-update/${rowData?.customer_id}`, {
        is_priority: newValue,
      });
      fetchSuccess("Premium updated successfully");
      fetchData();
    } catch (error) {
      fetchError(" Unable to updated premium");
    }
  };

  const handleStatus = async (rowData, isActive) => {
    try {
      await axios.put(`/customer/is-active/${rowData?.customer_id}`, {
        is_active: isActive,
      });
      fetchSuccess("Status updated successfully");
      fetchData();
    } catch (error) {
      fetchError(" Unable to updated status");
    }
  };
  const ticketOptions = {
    selectableRows: "none",
    selectableRowsHeader: false,
    elevation: 0,
    setRowProps: (row, dataIndex) => {
      const isEscalated = rows[dataIndex]?.is_escalated;
      return {
        style: {
          backgroundColor: isEscalated ? "#ffaba7" : null,
        },
      };
    },
  };

  const ticketColumns = [
    { name: "category_name", label: "Category" },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const displayValue =
            value?.length > 20 ? `${value.substring(0, 20)}...` : value;
          return (
            <Tooltip title={value}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "employee_name", label: "Assignee" },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    { name: "status_name", label: "Status" },
  ];
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card
          style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
          >
            <SoftButton variant="gradient" color="info" onClick={handleOpen}>
              Add Subscription
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={() => navigate(`/add-customer`)}
            >
              Add Customer
            </SoftButton>
          </div>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <ThemeProvider theme={getMuiTheme}>
              <MUIDataTable
                className="manage-customers"
                title={"Manage Customers"}
                data={rows}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </SoftBox>

          {loading ? (
            <PageLoader />
          ) : (
            rows.map(
              (row) =>
                expanded === row.customer_id && (
                  <Accordion
                    key={row.customer_id}
                    expanded={expanded === row.customer_id}
                    onChange={() => handleAccordionToggle(row.customer_id)}
                  >
                    <SoftBox
                      sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                          "& td": {
                            borderBottom: ({
                              borders: { borderWidth, borderColor },
                            }) => `${borderWidth[1]} solid ${borderColor}`,
                          },
                        },
                      }}
                    >
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          className="manage-customers"
                          title={"Customer Tickets"}
                          data={customerTickets}
                          columns={ticketColumns}
                          options={ticketOptions}
                        />
                      </ThemeProvider>
                    </SoftBox>
                  </Accordion>
                )
            )
          )}

          {modalOpen && (
            <AddSubscriptionModal
              isOpen={modalOpen}
              onClose={handleClose}
              fetchData={fetchData}
            />
          )}
          {addressOpen && (
            <AddAddress
              isOpen={addressOpen}
              onClose={handleAddressClose}
              customerId={customerId}
              fetchData={fetchData}
              fetchSuccess={fetchSuccess}
              fetchError={fetchError}
            />
          )}
        </Card>
      </DashboardLayout>
    </>
  );
};

export default CustomerList;
