import React, { useRef } from "react";
import axios from "../../api";
import { Button, TextField, Grid, Box, Modal } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "components/AlertMessages/SnackbarContext";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461", // Lighter black color
    borderRadius: "8px", // Curved border
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666", // Lighter black color on hover
  },
};

const validationSchema = Yup.object({
  template_name: Yup.string().required("Template name is required"),
  template_subject: Yup.string().required("Template subject is required"),
  template_content: Yup.string().required("Template content is required"),
  asset_type_id: Yup.string().required("Asset Type is required"),
});

const EditTemplate = ({ isOpen, onClose, templateData, fetchData }) => {
  const { fetchError, fetchSuccess } = useSnackbar();
  const editorRef = useRef(null);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="otp-modal"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          minWidth: 500,
          minHeight: 220,
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          ...customScrollbarStyle,
        }}
      >
        <Typography variant="h5" style={{ marginBottom: "18px" }}>
          Edit Template
        </Typography>
        <Formik
          initialValues={{
            template_name: templateData?.template_name || "",
            template_subject: templateData?.template_subject || "",
            template_content: templateData?.template_content || null,
            asset_type_id: templateData?.asset_type_id || "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              const response = await axios.put(
                `/email-templates/${templateData?.template_id}`,
                values
              );
              fetchSuccess(response.data.message);
              fetchData();
              onClose();
            } catch (error) {
              fetchError(error.response.data.error);
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Asset Type
                    </SoftTypography>
                    <TextField
                      name="asset_type_id"
                      fullWidth
                      variant="outlined"
                      disabled
                      value={templateData.asset_type_name}
                      onChange={handleChange}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Template Name
                    </SoftTypography>
                    <TextField
                      name="template_name"
                      fullWidth
                      variant="outlined"
                      disabled
                      value={values.template_name}
                      onChange={handleChange}
                      error={errors.template_name && touched.template_name}
                      helperText={touched.template_name && errors.template_name}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Template Subject
                    </SoftTypography>
                    <TextField
                      name="template_subject"
                      fullWidth
                      variant="outlined"
                      value={values.template_subject}
                      onChange={handleChange}
                      error={
                        !!errors.template_subject && touched.template_subject
                      }
                      helperText={
                        touched.template_subject && errors.template_subject
                      }
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Template Content
                    </SoftTypography>
                    <ReactQuill
                      ref={editorRef}
                      value={values.template_content}
                      onChange={(content, delta, source, editor) => {
                        setFieldValue("template_content", content);
                      }}
                      onBlur={(range, source, quill) => {
                        handleBlur({ target: { name: "template_content" } });
                      }}
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ align: [] }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "list",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "align",
                        "link",
                        "image",
                      ]}
                    />
                    {touched.template_content && errors.template_content && (
                      <div
                        className="error"
                        style={{
                          color: "#ea0606",
                          fontSize: "13px",
                          marginLeft: "13px",
                          marginTop: "6px",
                        }}
                      >
                        {errors.template_content}
                      </div>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="success-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Save
                  </Button>
                  <Button
                    className="cancel-btn"
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                    }}
                    variant="contained"
                    color="warning"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditTemplate;
