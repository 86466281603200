import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import axios from "../../../api";
import { userLogout } from "store/reducers/auth";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useSelector } from "react-redux";
import { Avatar, Typography } from "@mui/material";
import styled from "@emotion/styled";

const AvatarViewWrapper = styled("div")(({ theme }) => {
  return {
    cursor: "pointer",
    display: "flex",
  };
});
function DashboardNavbar({ absolute, light, isMini }) {
  const user = useSelector((state) => state.auth.user);
  const [controller, dispatch] = useSoftUIController();
  const {
    miniSidenav,
    fixedNavbar,
    // openConfigurator
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await axios.get("/auth/logout");
    localStorage.removeItem("token");
    dispatch(userLogout({ user: null }));
    navigate("/home");
    window.location.reload();
  };
  const handleSwapBack = async () => {
    const swapData = {
      userId: user?.actualUserId,
      userName: user?.actualName,
      userEmail: user?.actualEmail,
      userRole: [user?.actualRole],
      userRoleId: user?.actualRoleId,
    };
    try {
      await axios.post("/auth/switch-back", swapData);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <Toolbar sx={(theme) => navbarContainer(theme)}>
      <SoftBox
        className="home-icon"
        color="inherit"
        mb={{ xs: 1, md: 0 }}
        sx={(theme) => navbarRow(theme, { isMini })}
        onClick={() => navigate("/customer/profile")}
      >
        <Breadcrumbs icon="home" title={route} route={route} light={light} />
      </SoftBox>
      {isMini ? null : (
        <>
          <SoftBox
            sx={(theme) => navbarRow(theme, { isMini })}
            className="logout-icons"
          >
            <SoftBox color={light ? "white" : "inherit"} className="m-menu">
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {renderMenu()}
            </SoftBox>
            <SoftBox
              className="profile-pic"
              color={light ? "white" : "inherit"}
              sx={{ display: "flex" }}
            >
              <AvatarViewWrapper>
                <Avatar
                  sx={{
                    width: { xs: 30, lg: 64 },
                    height: { xs: 30, lg: 64 },
                    cursor: "pointer",
                  }}
                  src={
                    user?.profileImage
                      ? `${process.env.REACT_APP_CLOUD_FRONT_URL}/${user?.profileImage}`
                      : null
                  }
                />
                <Typography variant="h6">{user?.name}</Typography>
              </AvatarViewWrapper>
              {user?.actualUserId && (
                <IconButton aria-label="edit" onClick={handleSwapBack}>
                  <SwapHorizIcon />
                </IconButton>
              )}
              <Link to="/">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    className="logout-btn"
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                    onClick={handleLogout}
                  >
                    logout
                  </Icon>
                </IconButton>
              </Link>
            </SoftBox>
          </SoftBox>
        </>
      )}
    </Toolbar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
