import { Provider as ReduxProvider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { store } from "store";
import { SnackbarProvider } from "components/AlertMessages/SnackbarContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </SoftUIControllerProvider>
    </BrowserRouter>
  </ReduxProvider>
);
