import * as React from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import axios from "../../api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  });

const options = {
  selectableRows: "none",
  selectableRowsHeader: false,
  elevation: 0,
};

const CustomerAssetsList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState(null);
  const user = useSelector((state) => state.auth.user);
  const columns = [
    { name: "asset_service_id", label: "Service Id" },
    {
      name: "asset_name",
      label: "Asset Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const assetName = data?.asset_name;
          const displayValue =
            assetName?.length > 20
              ? `${assetName.substring(0, 20)}...`
              : assetName;
          return (
            <Tooltip title={assetName}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },
    { name: "asset_type_name", label: "Asset Type" },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          const city = data?.city_name;
          const state = data?.state_name;
          const addressType = data?.address_type;
          const addressData = `${value}, ${city}, ${state},${addressType}`;

          const displayValue =
            addressData?.length > 20
              ? `${addressData.substring(0, 20)}...`
              : addressData;
          return (
            <Tooltip title={addressData}>
              <span>{displayValue}</span>
            </Tooltip>
          );
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="view"
                onClick={() =>
                  navigate(`/customer/assets-view/${data?.asset_id}`)
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const fetchData = React.useMemo(
    () => async () => {
      try {
        const response = await axios.get(`/assets/customer/${user.userId}`);
        setRows(response?.data?.list);
      } catch (error) {
        setError(error);
      }
    },
    [user]
  );
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        style={{ padding: "24px", margin: "16px auto", maxWidth: "2000px" }}
      >
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              className="manage-assets"
              title={"Manage Assets"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          {error && <div>Error: {error.message}</div>}
        </SoftBox>
      </Card>
    </DashboardLayout>
  );
};

export default CustomerAssetsList;
